exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-questionnaire-flow-js": () => import("./../../../src/pages/questionnaire-flow.js" /* webpackChunkName: "component---src-pages-questionnaire-flow-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-in-the-media-js": () => import("./../../../src/templates/in-the-media.js" /* webpackChunkName: "component---src-templates-in-the-media-js" */),
  "component---src-templates-page-builder-js": () => import("./../../../src/templates/page-builder.js" /* webpackChunkName: "component---src-templates-page-builder-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-questionnaire-js": () => import("./../../../src/templates/questionnaire.js" /* webpackChunkName: "component---src-templates-questionnaire-js" */),
  "component---src-templates-video-js": () => import("./../../../src/templates/video.js" /* webpackChunkName: "component---src-templates-video-js" */),
  "component---src-templates-wiseman-tv-js": () => import("./../../../src/templates/wiseman-tv.js" /* webpackChunkName: "component---src-templates-wiseman-tv-js" */)
}

